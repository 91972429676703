<template>
    <div class="flex-1 max-w-2/5 flex flex-col justify-between">
        <!-- Tutor Header -->
        <div class="flex flex-row justify-between items-center p-4 bg-gray-100 border-b border-gray-200">
            <button
                @click="$emit('go-to-tutorial')"
                class="transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-sm py-2 px-4 w-32 rounded">
                <span class="flex flex-row items-center justify-center">
                    <listSvg class="mr-1 h-4 fill-current" />
                    <span>Overview</span>
                </span>
            </button>
            <button
                @click="$emit('help')"
                class="ml-auto text-gray-700 hover:text-gray-800 text-sm">
                <span class="flex flex-row items-center justify-center">
                    <helpSvg class="mr-1 h-4 fill-current" />
                    <span>Help</span>
                </span>
            </button>
            <span class="flex flex-row items-center text-gray-700 ml-4 text-sm">
                <schoolSvg class="mr-1 h-4 fill-current" />
                <span>Lesson {{ lessonPosition }}</span>
            </span>
        </div>

        <!-- Tutor Body -->
        <div ref="lessonContent" class="flex flex-col flex-1 overflow-y-auto">
            <div class="p-4">
                <h1 class="text-3xl font-bold mb-3">{{ tutorial.title }}</h1>
                <ProfileId
                    class="text-gray-700"
                    :profile="tutorial.author"
                    size="small"
                />
            </div>
            <div class="lesson-content p-4 pb-20" v-html="parsedLessonContent"></div>
        </div>

        <!-- Tutor Footer -->
        <div
            class="flex flex-row p-4 bg-gray-100 border-t border-gray-200"
            :class="{
                'justify-between': (hasPreviousLesson && hasNextLesson) || (hasPreviousLesson && isLastLesson),
                'justify-end': (!hasPreviousLesson && hasNextLesson) || (!hasPreviousLesson && isLastLesson),
            }">
            <button
                v-if="hasPreviousLesson"
                @click="$emit('go-to-previous-lesson')"
                class="transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-sm py-2 px-4 w-32 rounded">
                <span class="flex flex-row items-center justify-center">
                    <arrowRightSvg class="mr-1 h-4 fill-current transform rotate-180" />
                    <span>Previous</span>
                </span>
            </button>
            <button
                v-if="hasNextLesson"
                @click="$emit('go-to-next-lesson')"
                class="transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-sm py-2 px-4 w-32 rounded">
                <span class="flex flex-row items-center justify-center">
                    <span>Next</span>
                    <arrowRightSvg class="ml-1 h-4 fill-current" />
                </span>
            </button>
            <button
                v-if="isLastLesson"
                @click="() => !tutorialCompleted && $emit('complete-tutorial')"
                class="transition duration-200 ease-in-out bg-green-500 text-white shadow-sm border border-green-600 text-sm py-2 px-4 w-32 rounded"
                :class="{
                    'hover:bg-green-600': !tutorialCompleted,
                    'cursor-not-allowed': tutorialCompleted,
                }">
                <span class="flex flex-row items-center justify-center">
                    <doneSvg class="mr-1 h-4 fill-current" />
                    <span>{{ tutorialCompleted ? 'Completed' : 'Complete' }}</span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import { mapState, mapGetters } from 'vuex';

import hljs from 'highlight.js/lib/core';
import bash from 'highlight.js/lib/languages/bash';
import php from 'highlight.js/lib/languages/php';
import plaintext from 'highlight.js/lib/languages/plaintext';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import cpp from 'highlight.js/lib/languages/cpp';
import diff from 'highlight.js/lib/languages/diff';

hljs.registerLanguage('bash', bash);
hljs.registerLanguage('php', php);
hljs.registerLanguage('plaintext', plaintext);
hljs.registerLanguage('java', java);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('c++', cpp);
hljs.registerLanguage('c', cpp);
hljs.registerLanguage('diff', diff);

// FIXME::ckarundu Explore escaping html instead
hljs.configure({ ignoreUnescapedHTML: true });

export default {
    name: 'Tutor',

    props: {
        tutorial: {
            type: Object,
            required: true,
        },

        lesson: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState('project', [
            'projectHost',
        ]),

        ...mapGetters('tutorials', [
            'lessons',
        ]),

        lessonContent() {
            return this.lesson.content[0].content;
        },

        parsedLessonContent() {
            return this.parseContent(this.lessonContent);
        },

        lessonIndex() {
            const lessonIndex = this.lessons
                .findIndex((l) => l._id === this.lesson._id);
            return lessonIndex;
        },

        lessonPosition() {
            return `${this.lessonIndex + 1}/${this.lessons.length}`;
        },

        hasNextLesson() {
            return (this.lessonIndex + 1) !== this.lessons.length;
        },

        hasPreviousLesson() {
            return (this.lessonIndex + 1) !== 1;
        },

        isLastLesson() {
            return (this.lessonIndex + 1) === this.lessons.length;
        },

        tutorialCompleted() {
            const totalLessons = this.lessons.length;
            const { userData } = this.tutorial;
            if (userData === undefined) return false;
            return userData.stat.completed.length === totalLessons;
        },
    },

    mounted() {
        this.resetLessonScroll();
        this.highlightCodeSamples();
    },

    updated() {
        this.highlightCodeSamples();
        this.resetLessonScroll();
    },

    methods: {
        parseContent(content) {
            const parsers = [{
                matcher: /<code>\{\$\{INGRESS_LINK\}(.*?)\}<\/code>/g,
                replacer: `<code>${this.projectHost}$1</code>`,
            }, {
                matcher: /\{\$\{INGRESS_LINK\}(.*?)\}/g,
                replacer: `<a href="${this.projectHost}$1" target="_blank">${this.projectHost}$1</a>`,
            }];

            const parsedContent = parsers.reduce((baseString, parser) => {
                const parsedString = baseString.replace(
                    parser.matcher,
                    parser.replacer,
                );
                return parsedString;
            }, content);

            return parsedContent;
        },

        resetLessonScroll() {
            this.$refs.lessonContent.scrollTop = 0;
        },

        highlightCodeSamples() {
            this.$refs.lessonContent.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightElement(block);
            });
        },
    },

    components: {
        ProfileId: () => import('@/components/ui/ProfileId.vue'),
        arrowRightSvg: () => import('@/assets/img/arrowRight.svg'),
        doneSvg: () => import('@/assets/img/done.svg'),
        listSvg: () => import('@/assets/img/list.svg'),
        helpSvg: () => import('@/assets/img/help.svg'),
        schoolSvg: () => import('@/assets/img/school.svg'),
    },
};
</script>

<style lang="postcss">
.lesson-content {
    & h1 {
        font-size: 1.875rem;
        font-weight: 600;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & h3 {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    & p {
        font-size: 1rem;
        color: theme('colors.gray.800');
    }

    & a {
        &:link,
        &:visited {
            color: theme('colors.orange.500');
        }

        &:hover,
        &:active {
            color: theme('colors.orange.600');
        }
    }

    & ul,
    & ol {
        padding-left: 1rem;
        margin-top: .5rem;
        margin-bottom: 1rem;
        color: theme('colors.gray.700');
    }

    & ul {
        list-style-type: disc;
    }

    & ol {
        list-style-type: decimal;
    }

    & li {
        font-size: 1rem;
        margin-bottom: .25rem;
    }

    & code {
        font-size: .75rem;
        background-color: theme('colors.gray.100');
        border-radius: 3px;
    }

    & pre code {
        font-size: .875rem;
        padding: .75rem 1rem;
        margin: 1rem 0 1rem 0;
        background-color: theme('colors.codesample');
        color: theme('colors.gray.100');
    }

    & p code,
    & li code,
    & table code {
        border: 1px solid theme('colors.gray.200');
        padding: .1rem .3rem;
        color: theme('colors.red.700');
    }

    & .doc__image {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
</style>
