var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 max-w-2/5 flex flex-col justify-between"},[_c('div',{staticClass:"flex flex-row justify-between items-center p-4 bg-gray-100 border-b border-gray-200"},[_c('button',{staticClass:"transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-sm py-2 px-4 w-32 rounded",on:{"click":function($event){return _vm.$emit('go-to-tutorial')}}},[_c('span',{staticClass:"flex flex-row items-center justify-center"},[_c('listSvg',{staticClass:"mr-1 h-4 fill-current"}),_c('span',[_vm._v("Overview")])],1)]),_c('button',{staticClass:"ml-auto text-gray-700 hover:text-gray-800 text-sm",on:{"click":function($event){return _vm.$emit('help')}}},[_c('span',{staticClass:"flex flex-row items-center justify-center"},[_c('helpSvg',{staticClass:"mr-1 h-4 fill-current"}),_c('span',[_vm._v("Help")])],1)]),_c('span',{staticClass:"flex flex-row items-center text-gray-700 ml-4 text-sm"},[_c('schoolSvg',{staticClass:"mr-1 h-4 fill-current"}),_c('span',[_vm._v("Lesson "+_vm._s(_vm.lessonPosition))])],1)]),_c('div',{ref:"lessonContent",staticClass:"flex flex-col flex-1 overflow-y-auto"},[_c('div',{staticClass:"p-4"},[_c('h1',{staticClass:"text-3xl font-bold mb-3"},[_vm._v(_vm._s(_vm.tutorial.title))]),_c('ProfileId',{staticClass:"text-gray-700",attrs:{"profile":_vm.tutorial.author,"size":"small"}})],1),_c('div',{staticClass:"lesson-content p-4 pb-20",domProps:{"innerHTML":_vm._s(_vm.parsedLessonContent)}})]),_c('div',{staticClass:"flex flex-row p-4 bg-gray-100 border-t border-gray-200",class:{
            'justify-between': (_vm.hasPreviousLesson && _vm.hasNextLesson) || (_vm.hasPreviousLesson && _vm.isLastLesson),
            'justify-end': (!_vm.hasPreviousLesson && _vm.hasNextLesson) || (!_vm.hasPreviousLesson && _vm.isLastLesson),
        }},[(_vm.hasPreviousLesson)?_c('button',{staticClass:"transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-sm py-2 px-4 w-32 rounded",on:{"click":function($event){return _vm.$emit('go-to-previous-lesson')}}},[_c('span',{staticClass:"flex flex-row items-center justify-center"},[_c('arrowRightSvg',{staticClass:"mr-1 h-4 fill-current transform rotate-180"}),_c('span',[_vm._v("Previous")])],1)]):_vm._e(),(_vm.hasNextLesson)?_c('button',{staticClass:"transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-sm py-2 px-4 w-32 rounded",on:{"click":function($event){return _vm.$emit('go-to-next-lesson')}}},[_c('span',{staticClass:"flex flex-row items-center justify-center"},[_c('span',[_vm._v("Next")]),_c('arrowRightSvg',{staticClass:"ml-1 h-4 fill-current"})],1)]):_vm._e(),(_vm.isLastLesson)?_c('button',{staticClass:"transition duration-200 ease-in-out bg-green-500 text-white shadow-sm border border-green-600 text-sm py-2 px-4 w-32 rounded",class:{
                'hover:bg-green-600': !_vm.tutorialCompleted,
                'cursor-not-allowed': _vm.tutorialCompleted,
            },on:{"click":() => !_vm.tutorialCompleted && _vm.$emit('complete-tutorial')}},[_c('span',{staticClass:"flex flex-row items-center justify-center"},[_c('doneSvg',{staticClass:"mr-1 h-4 fill-current"}),_c('span',[_vm._v(_vm._s(_vm.tutorialCompleted ? 'Completed' : 'Complete'))])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }